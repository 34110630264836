import React from "react"

import {
  Modal,
  // ModalTitle,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  //   Button,
} from "react-bootstrap"

const ScheduleModal = props => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ border: "none" }} />
        <Modal.Body className="schedule-modal">
          {/* <div className="heading-line2 mx-auto mb-3" />
          <p>
            JBL Sourcing is a provider of full-service recruitment, retention,
            and other consultative solutions. Our niche area integrates us at
            the highest levels of leadership. Our clients enjoy a full array of
            solutions customized to meet their unique and individual needs,
            including Retained and Contingency Search, Interim Staffing, and
            Succession Planning.
          </p>
          <p>
            We listen carefully to our clients’ corporate desired outcome. We
            identify the best course of action and work with diligence to
            provide the solution. We measure our success by solving client
            problems and putting the best person in the right position.
          </p>
          <p>
            We build strong relationships with our clients that get to the heart
            of achieving their objectives and creating pathways to success.
          </p>
          <p>
            Our recruiting systems enable us to evaluate and precision-match
            candidates with specific client needs. We do this while maintaining
            low interview to placement ratios.
          </p> */}
          <iframe
            src="https://calendar.x.ai/justinball/phone"
            title="Schedule a meeting with Justin"
            width="100%"
            className="schedule-frame"
            scrolling="no"
            frameBorder="0"
          />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default ScheduleModal
