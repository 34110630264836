import React from "react"
import { Link } from "gatsby"

const footer = props => {
  return (
    <div>
      <div className="container-fluid footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h5 className="p-relative">
                <strong>Quick Links</strong>
              </h5>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/#about">About</Link>
                </li>
                <li>
                  <Link to="#why-us">Why Us</Link>
                </li>
                <li>
                  <Link to="/#clients">Clients</Link>
                </li>
                <li>
                  <Link to="/#contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <h5 className="p-relative">
                <strong>About Us</strong>
              </h5>
              <p>
                JBL Sourcing is a specific, specially designed Medtech industry
                search and consulting firm that identifies, locates, and
                evaluates the highest performers for potential opportunities
                across the U.S. Our clients range across every industry and
                specialty niche within medical technology and pharmaceuticals,
                integrating us within the most comprehensive network of
                professionals.
              </p>
            </div>
            <div className="col-md-3 offset-md-1">
              <h5 className="p-relative">
                <strong>Connect with Us</strong>
              </h5>
              {/* <i className="fa fa-facebook"></i> */}
              <a
                href="https://www.linkedin.com/in/jbball/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="fa fa-linkedin"
                  style={{ padding: "10px 12px" }}
                />
              </a>
              {/* <i className="fa fa-twitter" style={{ padding: "10px 11px" }}></i> */}
              <a
                href="https://www.instagram.com/justin.b.ball/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="fa fa-instagram"
                  style={{ padding: "10px 11px" }}
                ></i>
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-5 pt-5">
            <small>© {new Date().getFullYear()} JBL Sourcing. All Rights Reserved.</small>
            <small>
              Designed and Powered by&nbsp;
              <a
                href="https://webriq.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                WebriQ
              </a>
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default footer
