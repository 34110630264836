import React, { useState } from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import logo from "../../images/logo-jbl-sourcing.png"
import logoMRI from "../../images/logo-mri.png"

import ScheduleModal from "../../components/modal/find-the-perfect/schedule"

export default () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <div className="navigation">
      <Nav className="flex-column">
        <Nav.Item className="header-logo sidebar d-flex">
          <Link to="/" className="side-logo">
            <img className="w-100 mb-3" src={logo} alt="JBL Sourcing" />
          </Link>          
        </Nav.Item>
        <Nav.Item>
          <Link to="/">Home</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/#about">About</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/#why-us">Why Us</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/#clients">Clients</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/#contact">Contact</Link>
        </Nav.Item>
        <Nav.Item>
          <button
            onClick={() => setModalShow(true)}
            className="btn-global hvr-icon-forward sidebar-cta d-none-desktop"
            type="button"
          >
            Find me the perfect...
            <i className="fa fa-chevron-right ml-2 hvr-icon"></i>
          </button>
          <ScheduleModal show={modalShow} onHide={() => setModalShow(false)} />
        </Nav.Item>
        {/* <Nav.Item>
        <a href="tel:303-951-6375">
          {" "}
          <small>
            <i className="fa fa-phone mr-3"></i>303-951-6375
          </small>
        </a>
      </Nav.Item>
      <Nav.Item>
        <a href="#">
          <small className="text-left">
            <i className="fa fa-envelope mr-3"></i>info@jblsourcing.com
          </small>
        </a>
      </Nav.Item> */}
      </Nav>
    </div>
  )
}
