import React, { useState } from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import logo from "../../images/logo-jbl-sourcing.png"
import logoMRI from "../../images/logo-mri.png"

import ScheduleModal from "../../components/modal/find-the-perfect/schedule"

const Header = () => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <header>
      <Sticky
        className={styl.stickyWrapper}
        stickyClassName={styl.isSticky}
        stickyStyle={{ transform: "unset", zIndex: "2" }}
      >
        <div className={`header ${styl.header} shadow-sm pt-lg-0 pt-md-2`}>
          <div className="container-fluid sub-header d-none-mobile">
            <div className="container">
              <div className="row">
                <div className="col-2 text-center p-0">
                  <a href="tel:3038185715">
                    <small>
                      <i className="fa fa-phone mr-3"></i>303-818-5715
                    </small>
                  </a>
                </div>
                <div className="col-2 text-center">
                  <a href="mailto:info@jblsourcing.com">
                    <small>
                      <i className="fa fa-envelope mr-3"></i>
                      info@jblsourcing.com
                    </small>
                  </a>
                </div>
                <div className="col-8 text-right">
                  <a
                    href="https://www.linkedin.com/in/jbball/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fa fa-linkedin"
                      style={{ padding: "10px 12px" }}
                    />
                  </a>
                  {/* <i className="fa fa-twitter" style={{ padding: "10px 11px" }}></i> */}
                  <a
                    href="https://www.instagram.com/justin.b.ball/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fa fa-instagram"
                      style={{ padding: "10px 11px" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-3">
                <div className="header-logo d-flex">
                  <Link to="/">
                    <img src={logo} alt="JBL Sourcing" />
                  </Link>
                </div>
              </div>
              <div className="col text-right d-none-mobile">
                {/* <button
                  onClick={() => setModalShow(true)}
                  // href="https://calendar.x.ai/i/31POdX?tz=America~2FNew_York"
                  // rel="noopener noreferrer"
                  target="_blank"
                  className="btn-global hvr-icon-forward"
                  type="button"
                >
                  Find me the perfect...
                  <i className="fa fa-chevron-right ml-2 hvr-icon"></i>
                </button>*/}

                <ScheduleModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
              <div className="col-1 pr-0">
                <div className="text-right">
                  <SideBarMenu />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}

export default Header
